@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .wrapper {
    @apply max-w-[1440px] lg:px-[80px] m-auto;
  }
  .title {
    @apply bg-[#10162f] text-[#fff] p-[16px];
  }
  .additions {
    @apply bg-[#EEEEEE] p-[16px] flex items-center gap-2;
  }
  .btn-1 {
    @apply !text-[#10162f] font-[700];
  }
  #\:R3clkqH1\:,
  #\:R2clkqH1\:,
  #\:R4clkqH1\:,
  #\:R55kqH1\:,
  #\:R75kqH1\:,
  #\:R2ilkqH1\:,
  #\:R3ilkqH1\:,
  #\:R4ilkqH1\: {
    @apply !bg-[#10162f] !border-none;
  }
  #\:R3clkqH1\: button:hover,
  #\:R2clkqH1\: button:hover,
  #\:R4clkqH1\: button:hover,
  #\:R55kqH1\: button:hover,
  #\:R75kqH1\: button:hover,
  #\:R2ilkqH1\: button:hover,
  #\:R3ilkqH1\: button:hover,
  #\:R4ilkqH1\: button:hover {
    @apply bg-white;
  }
  #\:R1clkqH1\:,
  #\:R4clkqH1\, #\:R2ilkqH1\:,
  #\:R3ilkqH1\:,
  #\:R4ilkqH1\: {
    @apply !border-none;
  }
  a {
    @apply no-underline;
  }
  .search-wrap {
    @apply fixed w-full h-screen z-[1000]  origin-[center_bottom] transition-transform duration-[0.7s] ease-[ease] delay-[0s] scale-x-100 scale-y-0 left-0 top-0;
  }
  .search-wrap.active {
    @apply origin-[center_top] scale-100;
  }
  .search-inner {
    @apply absolute w-full h-full flex justify-center items-center;
    -webkit-box-pack: center;
    -webkit-box-align: center;
  }
  .search-overlay {
    @apply w-full h-full bg-[rgba(255,255,255,0.96)] !bg-[#10162f] bg-opacity-95 absolute z-[999] inset-0;
  }
  .search-form {
    @apply z-[9991] lg:w-6/12 relative -mt-20;
  }
  .seach-input {
    @apply w-full h-[65px] bg-transparent outline-0 text-[22px] lg:text-[26px] text-[rgb(68,68,68)] !text-[#EEEEEE] relative shadow-none mb-0 px-2.5 py-0 rounded-none;
  }
  .close-btn {
    @apply absolute text-[30px] text-[#10162f] !text-white cursor-pointer right-0 top-[25px];
  }
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
  .content * {
    @apply tracking-[0.25px];
    font-family: Source Sans Pro, sans-serif;
  }
  .content .container .logo {
    @apply w-10 h-10 border bg-white mr-[15px] rounded-[50%] border-solid border-[#828395];
  }
  .content .container .logo img {
    @apply w-full h-full bg-cover bg-no-repeat bg-center overflow-hidden rounded-[50%];
  }
  .content .container .company-logo {
    @apply flex items-center mt-0 mb-5 mx-0;
  }
  .content .container .company-logo a {
    @apply text-[#1972bc] text-2xl no-underline hover:underline;
  }
  .content .container .actions {
    @apply flex items-center flex-wrap gap-5 mb-[30px];
  }
  .content .container .actions a {
    @apply no-underline leading-9;
  }
  .content .container .actions a span {
    @apply flex items-center gap-[5px] uppercase text-base;
  }

  .topics {
    @apply flex items-center h-10 rounded px-3 text-sm transition-colors  text-black font-medium hover:bg-gray-300;
  }

  .sidebar_catalog {
    @apply border-r p-3 border-gray-100 flex flex-col overflow-y-auto transition-all xl2:w-full sm:w-1/2 w-full xl2:h-[600px] h-[100vh] xl2:static fixed top-0 left-0 z-[1000002] xl2:translate-x-0 -translate-x-full bg-white xl2:bg-transparent;
  }
  .sidebar_catalog.active {
    @apply translate-x-0;
  }
  .filterBar {
    @apply xl2:sticky xl2:top-[94px] xl2:h-max xl2:w-4/12 sm:w-1/2 w-full flex flex-col xl2:overflow-y-auto transition-all h-[100vh] fixed top-0 left-0 z-[1000002] xl2:z-10 xl2:translate-x-0 -translate-x-full bg-white;
  }
  .filterBar.active {
    @apply translate-x-0;
  }

  .profileMenu {
    @apply absolute overflow-hidden bg-white right-2 top-12 min-w-[205px] h-0 transition-all duration-300;
  }

  .profileMenu.active {
    @apply border border-black h-auto;
  }
}

:root {
  --blue-dark: #10162f;
}

body {
  overflow-x: hidden;
  font-size: 10px;
  font-family: "Apercu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.seach-input {
  border-bottom: 2px solid rgb(221, 221, 221);
}

.pattern {
  background-image: radial-gradient(#10162f 0.5px, transparent 0.5px);
  background-size: 12px 12px;
  background-color: rgba(255, 255, 255, 0);
}

@media (min-width: 567px) {
  .showNav {
    max-width: 300px;
  }
}

.showNav {
  height: 100vh;
  transform: none;
  transition: 500ms ease-in;
}

.closeNav {
  max-width: 300px;
  height: 100vh;
  transform: translateX(-300px) translateY(0px) translateZ(0px);
  transition: 500ms ease-in-out;
}

:focus,
:hover {
  -webkit-tap-highlight-color: transparent;
}

.bottom-b:not(:last-child) {
  border-bottom: 1px solid #e4e8ee;
}

.scroll-header {
  border-bottom: 1px solid #10162f;
}

:focus,
:hover {
  -webkit-tap-highlight-color: transparent;
}

.custom-calendar {
  border-radius: 4px;
  border: 2px solid #dee2e8;
  background: #fff;
  position: relative;
  width: 80px;
  height: 80px;
}
.custom-calendar::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  left: 14px;
  top: -7px;
  background: #2e3131;
  border-radius: 4px;
}

.custom-calendar::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 16px;
  right: 14px;
  top: -7px;
  background: #2e3131;
  border-radius: 4px;
}

.event-item {
  width: 30%;
}

@media (max-width: 992px) {
  .event-item {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .event-item {
    width: 100%;
  }
}

.hide-drop {
  top: 32px;
  max-height: 0;
  transition: max-height 200ms ease-out;
  overflow: hidden;
}
.show-drop {
  top: 32px;
  max-height: 600px;
  transition: max-height 150ms ease-in;
}

.slick-dots {
  position: absolute;
  bottom: 10px;
  list-style: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.slick-dots li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 5px;
  cursor: pointer;
}

.slick-dots li.slick-active {
  background-color: #555;
}
.slick-dots li button::before {
  display: none;
}
.slick-prev:before,
.slick-next::before {
  color: #ccc !important;
}

.partners-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.partners .partner-item {
  width: calc(100% / 6 - 20px) !important;
  padding: 5px;
  margin: 5px 10px;
}
.partner-item img {
  object-fit: contain;
  height: 120px;
  width: 100%;
}
@media (max-width: 992px) {
  .partners .partner-item {
    width: calc(100% / 3 - 20px) !important;
    margin: 10px 10px;
  }
}
@media (max-width: 768px) {
  .partners .partner-item {
    width: calc(100% / 2 - 20px) !important;
  }
}

.related-blog {
  height: min-content;
  background-color: #fff;
}

.related-blog .inner-blog:not(:last-child) {
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.inner-blog img {
  height: 76px;
  width: 76px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 3px;
}
